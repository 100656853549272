<template>
  <b-navbar
    style="background-color: white; box-shadow: none"
    class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  >
    <b-container class="text-light text-center">
      <b-col>
        <b-row class="align-items-center">
          <b-col cols="4" class="d-none d-md-block"> </b-col>
          <b-col cols="8" md="4" class=""
            ><b-img :src="img" rounded style="width: 180px"
          /></b-col>
          <b-col cols="4" class="">
            <b-button variant="outline-primary" size="sm" @click="selesai"
              ><feather-icon icon="LogInIcon" />Selesai</b-button
            ></b-col
          >
        </b-row>
      </b-col>
    </b-container>
  </b-navbar>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BNav,
  BNavItem,
  BFormRadio,
  BImg,
  BLink,
  BNavbarNav,
  BNavbar,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BNav,
    BNavItem,
    BFormRadio,
    BImg,
    BLink,
    BNavbar,
    BButton,
    BNavbarNav,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      img: require("@/assets/images/elp/ELP.png"),
      selected: "center",
    };
  },
  computed: {
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
  },
  methods: {
    selesai() {
      this.$store.commit("ujian/SET_CURRENT_HASIL_UJIAN", null);
      this.$router.push({
        name: "tryout-detail-subtest",
        query: { j: this.tryoutDetail.jadwal_id || this.$route.query?.j },
      });
    },
  },
};
</script>
