var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed",
    staticStyle: {
      "background-color": "white",
      "box-shadow": "none"
    }
  }, [_c('b-container', {
    staticClass: "text-light text-center"
  }, [_c('b-col', [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "4"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_c('b-img', {
    staticStyle: {
      "width": "180px"
    },
    attrs: {
      "src": _vm.img,
      "rounded": ""
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": _vm.selesai
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LogInIcon"
    }
  }), _vm._v("Selesai")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }